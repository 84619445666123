@tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 240 10% 3.9%;

      --card: 0 0% 100%;
      --card-foreground: 240 10% 3.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 240 10% 3.9%;

      --primary: 240 5.9% 10%;
      --primary-foreground: 0 0% 98%;

      --secondary: 240 4.8% 95.9%;
      --secondary-foreground: 240 5.9% 10%;

      --muted: 240 4.8% 95.9%;
      --muted-foreground: 240 3.8% 46.1%;

      --accent: 240 4.8% 95.9%;
      --accent-foreground: 240 5.9% 10%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --border: 240 5.9% 90%;
      --input: 240 5.9% 90%;
      --ring: 240 10% 3.9%;

      --radius: 0.5rem;
      --radial-bg-gradient: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%); /* Adjust the colors and stops as needed */
    --top-to-bottom-gradient: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%); /* Adjust the colors and stops as needed */
    --left-to-right-gradient: linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%); /* Adjust the colors and stops as needed */

    }

    .dark {
      --background: 240 10% 3.9%;
      --foreground: 0 0% 98%;

      --card: 240 10% 3.9%;
      --card-foreground: 0 0% 98%;

      --popover: 240 10% 3.9%;
      --popover-foreground: 0 0% 98%;

      --primary: 0 0% 98%;
      --primary-foreground: 240 5.9% 10%;

      --secondary: 240 3.7% 15.9%;
      --secondary-foreground: 0 0% 98%;

      --muted: 240 3.7% 15.9%;
      --muted-foreground: 240 5% 64.9%;

      --accent: 240 3.7% 15.9%;
      --accent-foreground: 0 0% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;

      --border: 240 3.7% 15.9%;
      --input: 240 3.7% 15.9%;
      --ring: 240 4.9% 83.9%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }

    

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#landing-page {
  position: relative; /* Ensure the container is positioned relative for absolute positioning of pseudo-element */
  /* background-image: url(./assets/img/landingpage.jpg); */
  background-position: center center;
  background-size: cover;
}
#breed-page, #contact-page {
  position: relative; /* Ensure the container is positioned relative for absolute positioning of pseudo-element */
  /* background-image: url(./assets/img/breed.jpg); */
 background-color:black;
  background-position: center center;
  background-size: cover;
  background: var(--radial-bg-gradient)
}

#landing-page::before,
#breed-page::before,
#contact-page::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--radial-bg-gradient)
}


#breed-page::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--top-to-bottom-gradient);
  z-index: 2; /* Ensure the top-to-bottom gradient is on top of the radial gradient */
}

#landing-page video,
#breed-page video,
#contact-page video,
.contact-banner {
  z-index: -1;
}


#know-scroll-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--left-to-right-gradient);
}


.gradient-border {
  border: 4px solid transparent;
  border-image: linear-gradient(to right, #00c6ff, #0072ff) 1;
  transition: border-image 0.3s ease-in-out;
}

.gradient-border:hover {
  border-image: linear-gradient(to right, #0072ff, #00c6ff) 1;
}


.bento-card-img-container {
  background-position: center center;
  background-size: cover;
}